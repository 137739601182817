$(document).ready(function() {
  //header nav
  $('.nav li').on('click', function() {
    $('.nav li').removeClass('is-active');
    $(this).addClass('is-active');
  });

  //tabs selection
  $('.nav-tabs a').click(function() {
    $(this).tab('show');
  });

  //select news view list-grid
  $('#list').click(function(event) {
    event.preventDefault();
    $('#products .news__item').addClass('list-group-item');

    //grid list icons active class
    $('#list').addClass('active');
    $('#grid').removeClass('active');
  });
  $('#grid').click(function(event) {
    event.preventDefault();
    $('#products .news__item').removeClass('list-group-item');
    $('#products .news__item').addClass('grid-group-item');

    //grid list icons active class
    $('#grid').addClass('active');
    $('#list').removeClass('active');
  });

  const $item = $('.news__item');
  const $city = $('.coverage__city');

  // for (let i = 0; i < 6; i++) {
  //   $item.clone().appendTo('.special');
  // }

  // for (let i = 0; i < 12; i++) {
  //   $city.clone().appendTo('.coverage__cities');
  // }

  $('#burger').on('click', function(event) {
    $(this).toggleClass('is-active', function() {
      $('.navbar-collapse').collapse('hide');
    });
  });

  // $('html').on('click', function() {
  //   $('.navbar-collapse').collapse('hide');
  //   $(this)
  //     .find('.hamburger')
  //     .toggleClass('is-active');
  // });

  //cards-slider
  if (typeof $('.slider').html() != 'undefined') {
    $('.slider').slick({
      dots: false,
      infinite: false,
      speed: 300,
      autoplay: true,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            infinite: true,
            dots: true,
          },
        },
      ],
    });
  }

  $('.selector')
    .find('.ua')
    .addClass('active');
  $('.selector')
    .find('.ua')
    .on('click', function(event) {
      $(this).addClass('active');
      $(this)
        .siblings()
        .removeClass('active');
    });

  $('.selector')
    .find('.en')
    .on('click', function(event) {
      $(this).addClass('active');
      $(this)
        .siblings()
        .removeClass('active');
    });
});
